<script setup lang="ts">
import { ref } from 'vue'
import type {DirectusUsers, Seas} from '~/types/directus.types'
import type {DropdownItem} from "#ui/types";

const { client, readItems, logout } = useDirectus()
const { setColor, container } = useWhitelabel()

const ready = ref(false)
const sea = useState<Seas>('sea')

const me = useState<DirectusUsers>('me')

const route = useRouter().currentRoute.value

async function logoutAction() {
  await logout()
  navigateTo({name: 'login', query: {ref: sea.value.slug}})
}

const userMenuItems: DropdownItem[][] = [
  [
    {
      label: `${me.value?.first_name} ${me.value?.last_name}`,
      slot: 'account',
      disabled: true,
    },
  ],
  [
    {
      label: 'Einstellungen',
      icon: 'i-ph-gear',
      to: `/${sea.value?.slug}/me`,
    },
  ],
  [
    {
      label: 'Abmelden',
      icon: 'i-ph-sign-out',
      click: logoutAction,
    },
  ],
]

async function initSea() {
  if (!route.params.sea)
    return
  await client.request(readItems('seas', {
    filter: {
      slug: {
        _eq: route.params.sea as string,
      },
    },
  })).then((res) => {
    if (res.length === 0)
      navigateTo('/')
    sea.value = res[0]
    setColor(sea.value.primary_color)
    ready.value = true
  })
}

onMounted(initSea)
</script>

<template>
  <div ref="container" class="min-h-screen flex flex-col bg-primary-100">
    <header>
      <UContainer>
        <div class="flex my-4 justify-between gap-3" v-if="ready">
          <NuxtImg v-if="sea.logo" :src="useAsset(sea.logo as string, 'sm')" class="h-12" />
          <div v-else class="shadow border border-base-100 rounded-full flex items-center">
            <p class="px-4 py-1 truncate">
              <span>{{ sea?.name ?? 'Oceana' }}</span>
            </p>
          </div>
          <UDropdown :items="userMenuItems" :ui="{ item: { disabled: 'cursor-text select-text' } }" :popper="{ placement: 'bottom-start' }">
            <UAvatar size="lg" :src="me.avatar ? useAsset(me.avatar as string) : null" :alt="`${me?.first_name} ${me?.last_name}`" class="shadow-lg" />

            <template #account="{ item }">
              <div class="text-left">
                <p>
                  Angemeldet als
                </p>
                <p class="truncate font-medium text-gray-900 dark:text-white">
                  {{ item.label }}
                </p>
              </div>
            </template>
          </UDropdown>
        </div>
      </UContainer>
    </header>
    <div class="flex-1 py-8">
      <UContainer v-if="ready">
        <slot />
      </UContainer>
      <ui-spinner v-else />
    </div>
    <footer class="footer p-8 bg-primary text-white" v-if="ready">
      <div class="flex items-center flex-col sm:flex-row gap-4 justify-center w-full">
        <div v-if="sea.website_link">
          <NuxtLink :to="sea.website_link" target="_blank">
            {{sea.name}}
          </NuxtLink>
        </div>
        <div v-if="sea.privacy_link">
          <NuxtLink :to="sea.privacy_link" target="_blank">
            Datenschutz
          </NuxtLink>
        </div>
        <div v-if="sea.imprint_link">
          <NuxtLink :to="sea.imprint_link" target="_blank">
            Impressum
          </NuxtLink>
        </div>
        <div>
          &copy; {{ (new Date()).getFullYear() }}
        </div>
      </div>
    </footer>
  </div>
</template>
